import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Image from 'components/Image';

import useDialog from 'hooks/useDialog';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import { useUser } from 'providers/UserProvider';

import APP from 'constants/app';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const AdCarouselItem = ({
  id,
  description,
  desktopImage,
  gaEventCategory,
  mobileImage,
  priority,
  tabletImage,
  url,
}) => {
  const { showDialog } = useDialog();
  const { isLoggedIn, loadingLoggedInUser } = useUser();

  const onItemForLoggedInUsersClick = () => {
    sendEvent({
      category: gaEventCategory,
      action: `small_ad_click__${id}`,
    });

    if (!isLoggedIn || loadingLoggedInUser) {
      showDialog(LoginDialog, {
        greetingsMessage: `${placeholder('titles.exclusiveOffer')} ${placeholder('titles.signInToAccessExclusiveOffer')}`,
      });
      return;
    }

    location.replace(url);
  };

  const sharedChildren = (
    <>
      <Image
        alt={description}
        className="rounded-3 bg-neutral-high-100 dark:bg-neutral-low-500 [@media(min-width:480px)]:hidden"
        height={280}
        priority={priority}
        sizes="(max-width: 768px) 280px, 1px"
        src={mobileImage}
        srcSet={`
      ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
      ${mobileImage} 280w,
    `}
        width={280}
      />
      <Image
        alt={description}
        className="hidden w-full rounded-3 bg-neutral-high-100 dark:bg-neutral-low-500 xl:!hidden [@media(min-width:480px)]:block"
        height={280}
        priority={priority}
        sizes="(max-width: 1280px) 576px, 1px"
        src={tabletImage}
        srcSet={`
      ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
      ${tabletImage} 576w,
    `}
        width={576}
      />
      <Image
        alt={description}
        className="hidden w-full rounded-3 bg-neutral-high-100 dark:bg-neutral-low-500 xl:block"
        height={280}
        priority={priority}
        sizes="(min-width: 1280px) 1152px, 1px"
        src={desktopImage}
        srcSet={`
      ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
      ${desktopImage} 1152w,
    `}
        width={1152}
      />
    </>
  );

  /**
   * Hardcoded ids to get this specific behavior during the BF.
   */
  if (id === 1937 || id === 1938 || id === 1941) {
    return (
      <div
        className="block h-full w-full cursor-pointer"
        role="button"
        tabindex="0"
        onClick={onItemForLoggedInUsersClick}
        onKeydown={onItemForLoggedInUsersClick}
      >
        {sharedChildren}
      </div>
    );
  }

  return (
    <a
      className="block h-full w-full cursor-pointer"
      href={url}
      onClick={() => {
        sendEvent({
          category: gaEventCategory,
          action: `small_ad_click__${id}`,
        });
      }}
    >
      {sharedChildren}
    </a>
  );
};

AdCarouselItem.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  desktopImage: PropTypes.string.isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  priority: PropTypes.bool.isRequired,
  tabletImage: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AdCarouselItem;
